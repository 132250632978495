import React, { useState, useContext } from "react";
import { useTranslation, Trans } from 'react-i18next';
import Kv from '../components/Kv';
import LanguageSwitcher from '../components/LanguageSwitcher';

const Announcement = ({onChangePage}) => {
    const { t, i18n } = useTranslation();

    return (
        <div className='page page-announcement' style={{
            backgroundImage: 'url("/images/bg-home.jpg")'
        }}>
            <div className='absolute top-sm right-md flex justify-end z-10' >
                <div className="text-[14px] flex gap-[4px]">
                    <button className={ i18n.language === 'en'?'underline': '' } onClick={()=>{i18n.changeLanguage('en')}}>EN</button>
                    <span>/</span> 
                    <button className={ i18n.language === 'tc'?'underline': '' } onClick={()=>{i18n.changeLanguage('tc')}}>繁</button>
                    <span>/</span>
                    <button className={ i18n.language === 'sc'?'underline': '' } onClick={()=>{i18n.changeLanguage('sc')}}>簡</button>
                </div>
            </div>
            <div className="pt-lg">
                <div className="kv-container">
                    <Kv />
                </div>
            </div>

            <div className="pb-lg">

                <section className="mb-lg">
                    <div className="inner">
                        <h2 className="gold-box text-md leading-tight font-bold">
                            <Trans i18nKey='end.heading' components={{br: <br />}}/>
                        </h2>
                    </div>
                </section>
                <section className="my-md">
                    <div className="inner">
                        <h2 className="mb-sm text-md leading-tight">
                            <Trans i18nKey='end.efficiency' components={{br: <br />}}/>
                        </h2>
                        <ul>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>...</li>
                        </ul>
                    </div>
                </section>
                <section className="my-md">
                    <div className="inner">
                        <h2 className="mb-sm text-md leading-tight">
                            <Trans i18nKey='end.shopaholic' components={{br: <br />}}/>
                        </h2>
                        <ul>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>...</li>
                        </ul>
                    </div>
                </section>
                <section className="my-md">
                    <div className="inner">
                        <h2 className="mb-sm text-md leading-tight">
                            <Trans i18nKey='end.business' components={{br: <br />}}/>
                        </h2>
                        <ul>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>...</li>
                        </ul>
                    </div>
                </section>
                <section className="my-md">
                    <div className="inner">
                        <h2 className="mb-sm text-md leading-tight">
                            <Trans i18nKey='end.foodie' components={{br: <br />}}/>
                        </h2>
                        <ul>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>...</li>
                        </ul>
                    </div>
                </section>
                <section className="my-md">
                    <div className="inner">
                        <h2 className="mb-sm text-md leading-tight">
                            <Trans i18nKey='end.pamperer' components={{br: <br />}}/>
                        </h2>
                        <ul>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>...</li>
                        </ul>
                    </div>
                </section>
                <section className="mt-lg">
                    <div className="inner">
                        
                        <h2 className="gold-box text-md leading-tight font-bold mb-md">
                            <Trans i18nKey='end.top' components={{br: <br />}}/>
                        </h2>
                        <ul>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                            <li>PENDING user</li>
                        </ul>
                    </div>
                </section>
                <div className="mt-md px-md">
                    <div className="my-lg">
                        <p className="text-sm text-center">
                            <Trans i18nKey="end.thankyou" components={{br: <br />}}/>
                        </p>
                    </div>
                    <p className="text-xs text-left">
                        <Trans i18nKey="home.promotion_license" components={{br: <br />}}/>
                    </p>
                </div>
            </div>

        </div>
    );
                
};


export default Announcement;